import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import GlobalStyles from './global-styles'
import Header from './header'

const footerCss = css`
  border-top: 1px solid #f2f2f3;

  display: flex;
  height: 90px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    max-width: 1360px;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyles />

      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
      </div>

      <footer css={footerCss}>
        Copyright © {new Date().getFullYear()}, Sikora Works. All right reserved
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
