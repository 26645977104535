import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import LogoImg from '../images/sikoraworks-logo.png'

const headerCss = css`
  position: sticky;
  top: 0;
  margin-bottom: 1.45rem;
  border-bottom: 1px solid #f2f2f3;
  z-index: 10;
  background: #fff;
`

const navCss = css`
  display: flex;
  height: 90px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    max-width: 1360px;
  }
`

const logoImgCss = css`
  height: 80px;
`

const Header = ({ siteTitle }) => (
  <header css={headerCss}>
    <div css={navCss}>
      <a href="/">
        <img css={logoImgCss} src={LogoImg} />
      </a>

      <ul>
        <li>
          <a href="mailto:info@sikoraworks.com">Contact</a>
        </li>
      </ul>
    </div>
    {/* <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div> */}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
