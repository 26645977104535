import React from 'react'
import { Global, css } from '@emotion/core'
import 'normalize.css'

export default function GlobalStyles() {
  return <Global styles={styles} />
}

const styles = theme => css`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSize};
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    color: ${theme.colors.black};
    line-height: 1.5;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: #4c77ce;
  }

  img {
    display: block;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h1 {
    margin: 10px 0;
    font-size: 36px;
  }

  h2 {
    margin: 10px 0;
    font-size: 24px;
  }
`
